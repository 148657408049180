<template>
  <div class="repair_detail">
    <div class="head_tool">
      <el-button type="primary" icon="el-icon-back" @click="goBack"
        >返回</el-button
      >
      <div class="btn_box">
        <el-button
          icon="el-icon-arrow-left"
          :type="hasPre ? 'primary' : ''"
          @click="changeAdvice(-1)"
        ></el-button>
        <el-button
          icon="el-icon-arrow-right"
          :type="hasNext ? 'primary' : ''"
          @click="changeAdvice(1)"
        ></el-button>
      </div>
    </div>

    <div class="repair_container">
      <div class="title">
        <div class="color"></div>
        <div class="name">工单详情</div>
      </div>
      <div class="content">
        <el-row class="row">
          <el-col :span="8" class="col" v-if="role === 1">
            <div class="label">运营商</div>
            <div class="value">{{ adviceInfo.name_operator }}</div>
          </el-col>
          <el-col :span="8" class="col">
            <div class="label">项目</div>
            <div class="value">{{ adviceInfo.name_project }}</div>
          </el-col>
          <el-col :span="8" class="col">
            <div class="label">提交时间</div>
            <div class="value">{{ adviceInfo.time_create }}</div>
          </el-col>
        </el-row>

        <el-row class="row">
          <el-col :span="8" class="col">
            <div class="label">提交账号</div>
            <div class="value">{{ adviceInfo.real_name }}</div>
          </el-col>
          <el-col :span="8" class="col">
            <div class="label">联系方式</div>
            <div class="value">{{ adviceInfo.tel }}</div>
          </el-col>
          <el-col :span="8" class="col">
            <div class="label">关联房间</div>
            <div class="value">{{ adviceInfo.room_name }}</div>
          </el-col>
        </el-row>

        <div class="repair_msg">
          <div class="label">报修详情</div>
          <div class="msg">{{ adviceInfo.content }}</div>
        </div>

        <div class="repair_imgs" v-if="adviceInfo.img_1">
          <div class="label">图片</div>
          <div class="img_box">
            <div class="img">
              <img
                @click="previewImg(adviceInfo.img_1)"
                :src="adviceInfo.img_1"
                alt=""
              />
            </div>
            <div class="img" v-if="adviceInfo.img_2">
              <img
                @click="previewImg(adviceInfo.img_2)"
                :src="adviceInfo.img_2"
                alt=""
              />
            </div>
            <div class="img" v-if="adviceInfo.img_3">
              <img
                @click="previewImg(adviceInfo.img_3)"
                :src="adviceInfo.img_3"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="finish_btn" v-if="!adviceInfo.action_type">
          <el-button type="primary" @click="handleAdvice">处理完成</el-button>
        </div>
      </div>
    </div>

    <ImagePreview ref="imagePreviewRef" />
  </div>
</template>

<script>
import { GetAdviceListApi, HandleAdviceApi } from '@/api'
import ImagePreview from '@/components/image_preview/image_preview.vue'

export default {
  components: { ImagePreview },
  data() {
    return {
      // 用户角色
      role: '',
      // 反馈id
      id: '',
      // 反馈详情
      adviceInfo: '',
      // 父组件带过来的id列表
      lis: []
    }
  },
  methods: {
    // 返回
    goBack() {
      this.$emit('changePage', { page: 1 })
    },

    // 父组件时间
    fatherEvent(id, lis) {
      this.lis = lis
      this.id = id
      this.getAdviceInfo()
    },

    // 切换意见
    changeAdvice(idx) {
      if (idx > 0) {
        // 下一条数据
        if (this.hasNext) {
          const index = this.lis.findIndex(i => i === this.id)
          this.id = this.lis[index + 1]
          this.getAdviceInfo()
        }
      } else {
        // 上一条数据
        if (this.hasPre) {
          const index = this.lis.findIndex(i => i === this.id)
          this.id = this.lis[index - 1]
          this.getAdviceInfo()
        }
      }
    },

    // 获取反馈详情
    async getAdviceInfo() {
      const params = {
        id: this.id,
        page_id: 0,
        page_num: 1
      }
      const { ret, data, msg } = await GetAdviceListApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      if (!data.data.length) {
        this.$emit('changePage', { page: 1 })
      }
      this.adviceInfo = data.data[0]
    },

    // 处理意见
    async handleAdvice() {
      const params = {
        id: this.adviceInfo.id
      }
      const { ret, data, msg } = await HandleAdviceApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.$message.success('处理成功')
      this.getAdviceInfo()
    },

    // 图片预览
    previewImg(src) {
      this.$refs.imagePreviewRef.open(src)
    }
  },
  created() {
    this.role = this.$store.getters.role
  },
  computed: {
    // 是否有上一条数据
    hasPre() {
      const index = this.lis.findIndex(i => i === this.id)
      if (index > 0) {
        return true
      }
      return false
    },
    // 是否有下一条数据
    hasNext() {
      const index = this.lis.findIndex(i => i === this.id)
      if (index < this.lis.length - 1) {
        return true
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.repair_detail {
  padding: 0 22px;
}

.head_tool {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.repair_container {
  min-height: calc(100vh - 200px);
  width: 100%;
  background-color: #ffffff;
  border-radius: 4px;

  .title {
    height: 48px;
    border-bottom: 1px solid #ebedf2;
    display: flex;
    align-items: center;

    .color {
      width: 4px;
      height: 18px;
      background: linear-gradient(-75deg, #50e379, #50e398);
      box-shadow: 0px 3px 4px 0px rgba(80, 227, 146, 0.35);
      margin: 0 11px 0 26px;
    }

    .name {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #12203e;
    }
  }

  .content {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 20px;

    .row {
      margin-bottom: 30px;

      .col {
        display: flex;
        align-items: center;
        padding: 0 20px;

        .label {
          width: 100px;
          text-align: right;
          padding-right: 16px;
          font-size: 14px;
          font-family: Alibaba;
          font-weight: normal;
          color: #12203e;
        }

        .value {
          flex: 1;
          height: 40px;
          background: #ffffff;
          border: 1px solid #f1f1f5;
          box-shadow: 0px 0px 6px 0px rgba(226, 231, 242, 0.35);
          border-radius: 10px;
          display: flex;
          align-items: center;
          padding-left: 24px;
          font-size: 14px;
          font-family: Alibaba;
          font-weight: normal;
          color: #12203e;
        }
      }
    }

    .repair_msg {
      display: flex;

      .label {
        width: 134px;
        font-size: 14px;
        font-family: Alibaba;
        font-weight: normal;
        color: #12203e;
        text-align: right;
        padding-right: 16px;
      }

      .msg {
        width: 100%;
        min-height: 131px;
        border: 1px solid #ebedf2;
        border-radius: 10px;
        padding: 10px 16px;
        font-size: 14px;
        font-family: Alibaba;
        font-weight: normal;
        color: #12203e;
      }
    }

    .repair_imgs {
      display: flex;
      margin-top: 30px;

      .label {
        width: 120px;
        font-size: 14px;
        font-family: Alibaba;
        font-weight: normal;
        color: #12203e;
        text-align: right;
        padding-right: 16px;
      }

      .img_box {
        flex: 1;
        display: flex;

        .img {
          width: 54px;
          height: 54px;
          border-radius: 8px;
          overflow: hidden;
          margin-right: 8px;
          background-color: #f0f2f7;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .finish_btn {
      margin-top: 80px;
      text-align: center;
    }
  }
}
</style>
