<template>
  <keep-alive>
    <component
      :is="getComponent"
      @changePage="changePage"
      ref="cptRef"
    ></component>
  </keep-alive>
</template>

<script>
import DeviceRepair from './device_repair/device_repair.vue'
import RepairDetail from './repair_detail/repair_detail.vue'

export default {
  name: 'repair',
  data() {
    return {
      // page  1:设备报修 2: 报修工单
      page: 1
    }
  },
  methods: {
    // 切换page标签页
    changePage({ id, page, lis }) {
      this.page = page
      if (page === 2) {
        this.$nextTick(() => {
          this.$refs.cptRef.fatherEvent(id, lis)
        })
      } else {
        this.$nextTick(() => {
          this.$refs.cptRef.getAdviceList()
        })
      }
    }
  },
  computed: {
    getComponent() {
      if (this.page === 1) {
        return DeviceRepair
      } else {
        return RepairDetail
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
