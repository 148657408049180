<template>
  <div class="device_repair">
    <div class="card">
      <!-- 标题 -->
      <div class="title_box">
        <div></div>
        <span>报修工单</span>
      </div>
      <!-- 选择器 -->
      <div class="selector">
        <el-select
          v-model="id_operator"
          placeholder="全部运营商"
          style="margin-right: 14px"
          class="w200"
          v-supAdmin
        >
          <el-option
            v-for="item in operatorList"
            :key="item.id_operator"
            :label="item.name_operator"
            :value="item.id_operator"
          >
          </el-option>
        </el-select>

        <el-select
          v-model="id_project"
          placeholder="全部项目"
          style="margin-right: 14px"
          class="w200"
        >
          <el-option
            v-for="item in computedProject"
            :key="item.id"
            :label="item.name_project"
            :value="item.id"
          >
          </el-option>
        </el-select>

        <el-select
          v-model="action_type"
          placeholder="全部状态"
          style="margin-right: 14px"
          class="w200"
        >
          <el-option
            v-for="item in [
              { id: 0, name: '未处理' },
              { id: 1, name: '已处理' }
            ]"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>

        <el-input
          class="w200"
          placeholder="输入用户名查询"
          v-model="user_name"
          prefix-icon="el-icon-search"
          style="margin: 10px 14px 0 0"
        ></el-input>

        <el-date-picker
          v-model="date"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          class="w240"
          style="margin: 10px 14px 0 0"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>

        <el-button type="success" style="margin: 10px 14px 0 0" @click="search">
          <i class="el-icon-search"></i>
          <span>查询</span>
        </el-button>
        <el-button type="info" @click="reset" style="margin: 10px 14px 0 0">
          <i class="el-icon-refresh-left"></i>
          <span>重置</span>
        </el-button>
        <el-button
          type="primary"
          icon="el-icon-download"
          @click="export2excel"
          style="margin: 10px 14px 0 0"
          >导出</el-button
        >
      </div>

      <!-- 表格 -->
      <div class="table_box">
        <el-table
          :data="adviceList"
          style="width: 100%"
          border
          :header-cell-style="{
            background: '#fafafb'
          }"
          size="small"
        >
          <el-table-column label="状态">
            <template v-slot="scope">
              <span v-if="scope.row.action_type">已处理</span>
              <span v-else class="edit">未处理</span>
            </template>
          </el-table-column>
          <el-table-column
            label="提交时间"
            prop="time_create"
          ></el-table-column>
          <el-table-column label="用户名" prop="user_name"></el-table-column>
          <el-table-column label="联系方式" prop="tel"></el-table-column>
          <el-table-column label="反馈内容" prop="content"></el-table-column>
          <el-table-column label="关联房间" prop="room_name"></el-table-column>
          <el-table-column label="操作">
            <template v-slot="scope">
              <span class="edit pointer" @click="toRepairDetail(scope.row)"
                >查看</span
              >
              <span
                v-if="scope.row.action_type"
                class="gray not_allow"
                style="margin-left: 12px"
                >标记为已处理</span
              >
              <span
                @click="handleAdvice(scope.row.id)"
                v-else
                class="edit cursor"
                style="margin-left: 8px"
                >标记为已处理</span
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 分页功能 -->
      <div class="pages">
        <el-pagination
          @size-change="sizeChange"
          @current-change="currentPageChange"
          :current-page="page_id + 1"
          :page-sizes="[20, 50, 100, 200]"
          :page-size="page_num"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { GetAdviceListApi, HandleAdviceApi } from '@/api'
import operator from '@/mixins/operator.js'
import project from '@/mixins/project.js'

export default {
  mixins: [operator, project],
  data() {
    return {
      // 权限级别
      level: '',
      // 运营商
      id_operator: '',
      // 项目
      id_project: '',
      // 处理状态
      action_type: '',
      // 用户名
      user_name: '',
      // 日期
      date: null,
      // 报修列表
      adviceList: [],
      // 分页
      total: 0,
      page_id: 0,
      page_num: 20
    }
  },
  methods: {
    // 分页尺寸变化
    sizeChange(size) {
      this.page_num = size
      this.getAdviceList()
    },
    // 当前页变化
    currentPageChange(page) {
      this.page_id = page - 1
      this.getAdviceList()
    },

    // 点击查询
    search() {
      this.page_id = 0
      this.getAdviceList()
    },

    // 点击重置
    reset() {
      this.id_operator = ''
      this.id_project = ''
      this.action_type = ''
      this.user_name = ''
      this.date = null
      this.page_id = 0
      this.getAdviceList()
    },

    // 获取参数
    getParams() {
      const params = {
        page_id: this.page_id,
        page_num: this.page_num
      }
      if (this.id_operator) {
        params.id_operator = this.id_operator
      }
      if (this.id_project) {
        params.id_project = this.id_project
      }
      if (this.action_type !== '') {
        params.action_type = this.action_type
      }
      if (this.user_name.trim()) {
        params.user_name = this.user_name.trim()
      }
      if (this.date) {
        params.time_start = this.date[0] + ' 00:00:00'
        params.time_end = this.date[1] + ' 23:59:59'
      }
      return params
    },

    // 获取报修工单
    async getAdviceList() {
      const params = this.getParams()
      const { ret, data, msg } = await GetAdviceListApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.total = data.cnt_all
      this.adviceList = data.data
    },

    // 标记为已处理
    async handleAdvice(id) {
      const params = { id }
      const { ret, data, msg } = await HandleAdviceApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.$message.success('标记成功')
      this.getAdviceList()
    },

    // 去报修详情
    toRepairDetail(row) {
      const lis = []
      this.adviceList.map(i => {
        lis.push(i.id)
      })
      this.$emit('changePage', { id: row.id, page: 2, lis })
    },

    // 点击导出
    export2excel() {
      this.$message.warning('功能持续研发中...')
    }
  },

  created() {
    this.level = this.$store.getters.level
    if (this.level === 100) {
      this.getOperatorList()
    }
    this.getProjectList()
    this.getAdviceList()
  },

  computed: {
    // 计算当前运营商下的项目
    computedProject() {
      if (this.id_operator) {
        return this.projectList.filter(i => {
          return i.id_operator === this.id_operator
        })
      } else {
        return this.projectList
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.device_repair {
  padding: 0 22px;
}

.card {
  min-height: calc(100vh - 160px);
  margin-top: 24px;

  .title_box {
    height: 48px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ebedf2;

    div {
      width: 4px;
      height: 18px;
      background: linear-gradient(-75deg, #50e379, #50e398);
      box-shadow: 0px 3px 4px 0px rgba(80, 227, 146, 0.35);
      margin: 0 11px 0 26px;
    }

    span {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #12203e;
    }
  }

  .selector {
    padding: 10px 20px 20px;
  }

  .table_box {
    padding: 0 22px;
  }
}
</style>
